import { environment } from '../../assets/environments/environment';

export const API_END_POINTS = {
  DELETE_PRODUCTION_LOSS_ENTRY: `${environment.apiBaseUrl}linestatus/productionlossentry/`,
  PRODUCTION_ENTRY_EDIT: `${environment.apiBaseUrl}linestatus/productionlossentry/`,
  LOGIN: `${environment.apiBaseUrl}login/userauthentication`,
  LOGOUT: `${environment.apiBaseUrl}login/logout`,
  REFRESH_TOKEN: `${environment.apiBaseUrl}login/refresh-refreshtoken`,

  GET_PLANT: `${environment.apiBaseUrl}plants`,
  POST_PLANT: `${environment.apiBaseUrl}plants`,
  UPDATE_PLANT: `${environment.apiBaseUrl}plants/`,
  ARCHIVE_PLANT: `${environment.apiBaseUrl}plants/`,
  RESTORE_PLANT: `${environment.apiBaseUrl}plants/`,
  GET_STATE: `${environment.apiBaseUrl}plants/state`,
  GET_ALL_PLANT_BY_CITY: `${environment.apiBaseUrl}plants/`,
  GET_FACTORY_BY_PLANTID: `${environment.apiBaseUrl}factory/`,
  GET_LINE_BY_FACTORYID: `${environment.apiBaseUrl}line/factories/`,
  GET_ALL_PLANT_LINES: `${environment.apiBaseUrl}line/plants/`,

  GET_MONTHLY_PRODUCTION: `${environment.apiBaseUrl}monthlystatus`,

  GET_USER: `${environment.apiBaseUrl}users/`,
  UPDATE_USER: `${environment.apiBaseUrl}users/`,
  ARCHIVE_USER: `${environment.apiBaseUrl}users/`,
  RESTORE_USER: `${environment.apiBaseUrl}users/`,
  GET_USER_ROLES: `${environment.apiBaseUrl}users/roles`,

  GET_DAILY_PRODUCTION_DATA: `${environment.apiBaseUrl}dailystatus/plants/`,
  GET_DAILY_PRODUCTION_DATA_BY_LINE: `${environment.apiBaseUrl}dailystatus`,
  GET_DAILY_PRODUCTION_DATA_BY_HOURS: `${environment.apiBaseUrl}hourlystatus`,
  GET_DAILY_PRODUCTION_SHIFT: `${environment.apiBaseUrl}dailystatus/shift`,

  GET_ALARM_CODE: `${environment.apiBaseUrl}alarmCodes/plants/`,
  GET_ALARM_CODE_BY_LINE: `${environment.apiBaseUrl}alarmCodes/line/`,
  POST_ALARM_CODE: `${environment.apiBaseUrl}alarmCodes`,
  UPDATE_ALARM_CODE: `${environment.apiBaseUrl}alarmCodes/`,
  ARCHIVE_ALARM_CODE: `${environment.apiBaseUrl}alarmCodes/`,
  RESTORE_ALARM_CODE: `${environment.apiBaseUrl}alarmCodes/`,
  IMPROT_ALARM_CODE: `${environment.apiBaseUrl}alarmcodes/bulkinsertalarmcodes`,

  GET_MACHINE: `${environment.apiBaseUrl}machine`,
  GET_MACHINE_BY_LINE_ID: `${environment.apiBaseUrl}machine/lines/`,

  GET_STATION_BY_MACHINE_ID: `${environment.apiBaseUrl}station`,
  GET_STATION_BY_LINE_ID: `${environment.apiBaseUrl}station/lines/`,

  GET_NGCODE_BY_PLANT_OR_FACTORY: `${environment.apiBaseUrl}ngcodes/plants/`,
  GET_NGCODE_BY_LINE: `${environment.apiBaseUrl}ngcodes/lines/`,
  POST_NGCODE: `${environment.apiBaseUrl}ngcodes`,
  UPDATE_NGCODE: `${environment.apiBaseUrl}ngcodes/`,
  ARCHIVE_NGCODE_CODE: `${environment.apiBaseUrl}ngcodes/`,
  RESTORE_NGCODE_CODE: `${environment.apiBaseUrl}ngcodes/`,
  IMPORT_NGCODE_CODE: `${environment.apiBaseUrl}ngcodes/bulkinsert`,

  GET_SAFETY_SECURITY_DETAILS: `${environment.apiBaseUrl}safetysecuritydetails/plants/`,
  GET_SAFETY_SECURITY_DETAILS_BY_LINE: `${environment.apiBaseUrl}safetysecuritydetails/`,
  POST_SAFETY_SECURITY_DETAILS: `${environment.apiBaseUrl}safetysecuritydetails`,
  UPDATE_SAFETY_SECURITY_DETAILS: `${environment.apiBaseUrl}safetysecuritydetails/`,
  DELETE_SAFETY_SECURITY_DETAILS: `${environment.apiBaseUrl}safetysecuritydetails/`,

  GET_PRODUCTION_LOSS_REASON: `${environment.apiBaseUrl}productionlossreasons`,
  POST_PRODUCTION_LOSS_REASON: `${environment.apiBaseUrl}productionlossreasons`,
  UPDATE_PRODUCTION_LOSS_REASON: `${environment.apiBaseUrl}productionlossreasons/`,
  ARCHIVE_PRODUCTION_LOSS_REASON: `${environment.apiBaseUrl}productionlossreasons/`,
  RESTORE_PRODUCTION_LOSS_REASON: `${environment.apiBaseUrl}productionlossreasons/`,

  GET_LINE_INFO_INPUT_BY_PLANT_OR_FACTORY: `${environment.apiBaseUrl}lineinformationinputs/plants/`,
  GET_LINE_INFO_INPUT_BY_LINE: `${environment.apiBaseUrl}lineinformationinputs/`,
  GET_UPDATED_LINE_INFO: `${environment.apiBaseUrl}lineinformationinputs/updatedfields/`,
  POST_LINE_INFO_INPUT: `${environment.apiBaseUrl}lineinformationinputs`,
  UPDATE_LINE_INFO_INPUT: `${environment.apiBaseUrl}lineinformationinputs/lines/`,
  GET_LINE_INFORMATION_INPUTS: `${environment.apiBaseUrl}lineinformationinputs/line/`,
  PUT_LINE_INFORMATION_INPUTS: `${environment.apiBaseUrl}lineinformationinputs/`,

  GET_MODULE_EQUIPMENT_FAILURE_DOWNTIME: `${environment.apiBaseUrl}equipmentfailuredowntime`,

  GET_MODULE_EQUIPMENT: `${environment.apiBaseUrl}moduleequipment`,
  GET_MODULE_EQUIPMENT_QUALITY_ANALYSIS: `${environment.apiBaseUrl}moduleequipment/qualityanalysis`,
  GET_MODULE_EQUIPMENT_QUALITY_ANALYSIS_SCATTER_DATA: `${environment.apiBaseUrl}moduleequipment/qualityanalysis-scatter`,

  GET_LINE_MANAGEMENT: `${environment.apiBaseUrl}linemanagement`,
  POST_LINE_MANAGEMENT: `${environment.apiBaseUrl}linemanagement`,
  UPDATE_LINE_MANAGEMENT: `${environment.apiBaseUrl}linemanagement/`,
  ARCHIVE_LINE_MANAGEMENT: `${environment.apiBaseUrl}linemanagement/`,
  RESTORE_LINE_MANAGEMENT: `${environment.apiBaseUrl}linemanagement/`,
  IMPORT_LINE_MANAGEMENT: `${environment.apiBaseUrl}linemanagement/bulkinsert`,

  GET_COLUMNS_BY_MCHINE_ID: `${environment.apiBaseUrl}linemanagement/columnname`,
  GET_YAXIS_BY_COLUMN_NAME: `${environment.apiBaseUrl}linemanagement/yaxis`,

  LINE_STATUS: `${environment.apiBaseUrl}linestatus`,
  PROD_LOSS_ENTRY: `${environment.apiBaseUrl}linestatus/productionlossentry`,
  LINE_SHIFT_MAPPING: `${environment.apiBaseUrl}lineshiftmapping/overtime`,
  PROD_LOSS_REASON: `${environment.apiBaseUrl}productionlossreasons/losstype/`,

  PRODUCTIVITY_STATUS: `${environment.apiBaseUrl}producitivitystatus`,

  SHIFT_PRODUCTIVITY_STATUS: `${environment.apiBaseUrl}producitivitystatus/productivityshift`,
  DAILY_PRODUCTIVITY_STATUS: `${environment.apiBaseUrl}producitivitystatus/productivitydaily`,
  MONTHLY_PRODUCTIVITY_STATUS: `${environment.apiBaseUrl}producitivitystatus/productivitymonthly`,
  YEARLY_PRODUCTIVITY_STATUS: `${environment.apiBaseUrl}producitivitystatus/producitivityyearly`,

  SHIFT_BEKIDO_STATUS: `${environment.apiBaseUrl}producitivitystatus/bekidoshift`,
  DAILY_BEKIDO_STATUS: `${environment.apiBaseUrl}producitivitystatus/bekidodaily`,
  MONTHLY_BEKIDO_STATUS: `${environment.apiBaseUrl}producitivitystatus/bekidomonthly`,
  YEARLY_BEKIDO_STATUS: `${environment.apiBaseUrl}producitivitystatus/bekidoyearly`,

  EXPORT_API: `${environment.apiBaseUrl}export/export`,

  GET_ALERT_MANAGEMENT: `${environment.apiBaseUrl}alertmanagement`,
  POST_ALERT_MANAGEMENT: `${environment.apiBaseUrl}alertmanagement`,
  UPDATE_ALERT_MANAGEMENT: `${environment.apiBaseUrl}alertmanagement/`,
  GET_ALERT_MANAGEMENT_USERS: `${environment.apiBaseUrl}alertmanagement/users`,
  SYNC_AZURE_USER: `${environment.apiBaseUrl}users/user-sync`,
};
